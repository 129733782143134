import { Container, Grid, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomContainer = styled(Container)`
  && {
    padding-top: 411px;
    padding-bottom: 277px;
    position: relative;
    overflow: hidden;
    background: var(--light);
    z-index: 1;
    @media (max-width: 768px) {
      padding-top: 128px;
      padding-bottom: 64px;
    }
    @media (max-width: 1500px) {
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 56px;
    color: var(--primary);
    margin-bottom: 32px;
    @media (max-width: 768px) {
      font-size: 48px;
    }
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--dark);
    margin-bottom: 32px;
  }
`;

export const CustomTextServices = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 24px;
    color: var(--primary);
    text-transform: uppercase;
  }
`;

export const CustomGridItem = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 16px;
  }
`;

export const CustomImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  object-fit: contain;
  z-index: -1;
  @media (max-width: 1500px) {
    display: none;
  }
  @media (max-width: 1600px) {
    left: -100px;
  }
`;

export const CustomImg2 = styled.img`
  position: absolute;
  top: 241px;
  right: -50px;
  width: auto;
  height: auto;
  object-fit: contain;
  z-index: -1;
  @media (max-width: 1500px) {
    display: none;
  }

  @media (max-width: 1900px) {
    right: -250px;
  }
`;
