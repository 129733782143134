import React from "react";
import { Box, Container, Grid } from "@mui/material";
import {
  CustomTitle,
  CustomBox,
  CustomSubtitle,
  CustomText,
  CustomContainer,
  CustomSubTitle2,
  CustomContainer2,
  CustomTitle2,
  CustomText2,
  CustomLink,
} from "./style";
import PortFile from "../../../assets/download/4PORT.pdf";

export default function Section5() {
  return (
    <>
      <CustomContainer maxWidth="false" disableGutters>
        <Container maxWidth="lg">
          <CustomTitle>Relatório de Análise/Pesquisa</CustomTitle>
          <Grid
            container
            display={"flex"}
            gap={"32px"}
            justifyContent={"center"}
          >
            <Grid item xs={12} md={8} lg>
              <CustomBox>
                <CustomSubtitle>Transformação Digital</CustomSubtitle>
                <CustomText>
                  Pretendemos acabar com o papel através de melhorias de
                  workflows que assim o permitam, aumentando ainda a eficiência
                  da equipa.
                </CustomText>
              </CustomBox>
            </Grid>
            <Grid item xs={12} md={8} lg>
              <CustomBox>
                <CustomSubtitle>Indústria 4.0</CustomSubtitle>
                <CustomText>
                  Analisámos e apoiamos na transição estratégica para a
                  indústria 4.0. Em casos de necessidade, temos uma equipa de
                  desenvolvimento especializada em criar soluções específicas do
                  negócio.
                </CustomText>
              </CustomBox>
            </Grid>
            <Grid item xs={12} md={8} lg>
              <CustomBox>
                <CustomSubtitle>Consultoria Empresarial</CustomSubtitle>
                <CustomText>
                  Aprenda a construir o seu modelo de negócio. Realizar um
                  planeamento estratégico e compreender que metodologias a sua
                  equipa pode/deve adotar e como as implementar. Garanta a
                  criação de valor continuo.
                </CustomText>
              </CustomBox>
            </Grid>
          </Grid>
        </Container>
      </CustomContainer>

      <Container
        maxWidth="false"
        disableGutters
        sx={{
          background: "fff",
          height: "1300px",
          zIndex: "-1",
        }}
      >
        <CustomContainer2
          maxWidth="lg"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            <CustomTitle2>Último estudo realizado</CustomTitle2>
            <CustomSubTitle2>Benchmark de Smart Ports</CustomSubTitle2>
            <CustomText2>
              Realizámos um Benchmark de Smart Ports que elucida para as
              mudanças na atividade portuária que estão a decorrer a nível
              mundial, europeu e nacional. Conheça as últimas tendência do
              setor.
            </CustomText2>
            <CustomLink download href={PortFile}>
              Download
            </CustomLink>
          </Box>
        </CustomContainer2>
      </Container>
    </>
  );
}
