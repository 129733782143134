import styled from 'styled-components';
import { Container, Typography } from '@mui/material';
import BG10 from "../../../assets/images/BG10.png";

export const CustomContainer = styled(Container)`
&&{
    background-image: url(${BG10});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 226px 0px;
    display: flex;
    flex-direction: column;
    gap: 64px;
    align-items: center;
    justify-content: center;
}
`;

export const CustomTypography = styled(Typography)`
&&{
    font-family: Poppins Semibold;
    font-size: 26px;
    color:var(--light);
    text-align: center;
    width: 100%;
}
`;