import React from "react";
import { Container, Grid } from "@mui/material";
import {
  CustomTitle,
  CustomContainer,
  CustomText,
  CustomTextServices,
  CustomGridItem,
  CustomImg,
  CustomImg2,
} from "./style";
import radioactive from "../../../assets/images/radioactive.svg";
import ButtonUnderline from "../../ui/buttons/buttonUnderline";
import FormLine from "../../../assets/images/form-line.svg";
import Pin from "../../../assets/images/Pin.svg";
import BG73 from "../../../assets/images/73.svg";
import BG74 from "../../../assets/images/74.png";

export default function Section4() {
  return (
    <CustomContainer maxWidth={false} disableGutters>
      <CustomImg src={BG73} alt="" />
      <CustomImg2 src={BG74} alt="" />
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={6.5}>
            <CustomTitle>Embarcações</CustomTitle>
            <CustomText>
              Garanta que está tudo preparado para as embarcações, desde de
              entradas, a movimentos de cargas e outros serviços/registros de
              modo a que não falhe nada tanto a nível de eficiência e qualidade,
              como interno e legal.
            </CustomText>
            <ButtonUnderline>Saber mais</ButtonUnderline>
          </Grid>
        </Grid>
        <Grid container display={"flex"} gap={"33px"} marginTop={"88px"}>
          <CustomGridItem item xs={12} md={3}>
            <img src={radioactive} alt="" />
            <CustomTextServices>HAZMAT</CustomTextServices>
            <CustomText>
              Declaração de resíduos potencialmente perigosos a bordo da
              embarcação e no pátio
            </CustomText>
          </CustomGridItem>
          <CustomGridItem item xs={12} md={3}>
            <img src={FormLine} alt="" />
            <CustomTextServices>FAL OMI</CustomTextServices>
            <CustomText>
              Preenchimento automático de formulários da FAL OMI
            </CustomText>
          </CustomGridItem>
          <CustomGridItem item xs={12} md={3}>
            <img src={Pin} alt="" />
            <CustomTextServices>ISPS CODE</CustomTextServices>
            <CustomText>ISPS de acordo com a OMI</CustomText>
          </CustomGridItem>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
