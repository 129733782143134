import { Typography } from '@mui/material';
import styled from 'styled-components';

export const CustomTitle = styled(Typography)`
    &&{
        font-size: 24px;
        font-family:Poppins Medium;
        color: var(--primary);
        margin-bottom:32px;
        text-align:left;
    }
`;

export const CustomText = styled(Typography)`
    &&{
        font-size: 20px;
        font-family:Poppins Regular;
        color: var(--dark);
        text-align:left;
    }
`;

export const CustomTextOrange = styled(Typography)`
    &&{
        font-size: 20px;
        font-family:Poppins Regular;
        color: var(--orange);
        text-align:left;
    }
`;
