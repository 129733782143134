import React from "react";
import {
  CustomContainer,
  CustomTitle,
  CustomText,
  CustomGrid,
  CustomText2,
} from "./style";
import { Box, Container, Grid } from "@mui/material";
import Fatura from "../../../assets/images/fatura_temp.jpg";
import ButtonUnderline from "../../ui/buttons/buttonUnderline";
import Card from "../../card";
import ButtonOrange from "../../ui/buttons/buttonOrange";
import Certificacoes from "../../../assets/images/logos_bigdata.svg";

export default function Section7() {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ background: "var(--light)" }}
    >
      <CustomContainer maxWidth={false} disableGutters>
        <Container maxWidth="lg">
          <Grid container display={"flex"} justifyContent={"center"}>
            <Grid item xs={12} sm={12} md={6} paddingTop={"64px"}>
              <CustomTitle>Faturamento</CustomTitle>
              <CustomText>
                <b>Inframar</b>, <b>Infraporto</b> e <b>acostagem</b>
                <br /> Elaboração automática do boletim de faturamento a partir
                da informação inserida em todos os módulos de Gestão Portuária
              </CustomText>
              <ButtonUnderline>Saber mais</ButtonUnderline>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{ marginTop: { xs: "33px", lg: "0px" } }}
            >
              <img src={Fatura} alt="" style={{ maxWidth: "100%" }} />
            </Grid>
          </Grid>
          <Grid
            container
            display={"flex"}
            gap={"32px"}
            sx={{ marginTop: { xs: "0px", lg: "100px" } }}
          >
            <Grid item xs={12} md={5} />
            <Grid
              item
              xs={12}
              md={5}
              sx={{ marginTop: { xs: "128px", lg: "0px" } }}
            >
              <CustomTitle>Estatística</CustomTitle>
            </Grid>
          </Grid>
          <Grid container display={"flex"} gap={"32px"}>
            <CustomGrid item xs={12} sm={12} md={5} lg>
              <Card />
            </CustomGrid>
            <Grid item xs={12} sm={12} md={5} lg>
              <CustomText>
                Obtenção automática de toda a informação estatística do porto e
                preparação a envio das mensagens em XML para a ANTAQ.
                <br />
                Coletas de dados com segurança de informação de dados sensíveis;
                <br />
                Criação de novos relatórios;
              </CustomText>
              <Box sx={{ marginBottom: "16px" }}>
                <ButtonOrange marginBottom={12}>
                  Big Data Analytics
                </ButtonOrange>
              </Box>
              <CustomText>
                Inteligência Artificial para análises cognitivas e preditivas.
                <br />
                Big Data Analytics é uma parte integrante do Projeto Cognita,
                projeto cofinanciado pelo Fundo Europeu.
              </CustomText>
              <img src={Certificacoes} style={{ maxWidth: "100%" }} alt="" />
              <CustomText2>
                P&D Sistema de Incentivos à Pesquisa e Desenvolvimento
                Tecnológico nas Empresa
              </CustomText2>
            </Grid>
          </Grid>
        </Container>
      </CustomContainer>
    </Container>
  );
}
