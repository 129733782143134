import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import RelatorioWave from "../../../assets/images/relatorio_wave.svg";
import BubbleBottom from "../../../assets/images/BubbleBottom.svg";

export const CustomContainer = styled(Container)`
  && {
    margin-top: -275px;
    padding-top: 410px;
    padding-bottom: 526px;
    background-image: url(${RelatorioWave});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;
    @media (max-width: 768px) {
      padding-top: 200px;
      padding-bottom: 350px;
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-size: 56px;
    font-family: Poppins Semibold;
    color: var(--light);
    text-align: left;
    margin-bottom: 64px;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
`;

export const CustomBox = styled(Box)`
  && {
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    box-shadow: 0px 0px 3px #00f6ff;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 100%;
    border: 1px solid var(--light);
  }
`;

export const CustomSubtitle = styled(Typography)`
  && {
    font-size: 20px;
    font-family: Poppins Medium;
    color: var(--light);
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-size: 16px;
    font-family: Poppins Regular;
    color: var(--light);
  }
`;

export const CustomContainer2 = styled(Container)`
  && {
    background: url(${BubbleBottom}) no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding-top: 186px;
  }
`;

export const CustomTitle2 = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 56px;
    color: var(--orange);
    margin-bottom: 56px;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
`;

export const CustomSubTitle2 = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 36px;
    color: var(--light);
    margin-bottom: 32px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

export const CustomText2 = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 26px;
    color: var(--light);
    margin-bottom: 32px;
    max-width: 639px;
  }
`;

export const CustomLink = styled.a`
  && {
    background: var(--light);
    border-radius: 10px;
    box-shadow: 0px 0px 6px #a5b9ba;
    box-sizing: border-box;
    padding: 12px 24px;
    width: max-content;
    height: 56px;
    font-family: Poppins Medium;
    font-size: 20px;
    color: var(--orange);
    text-transform: none;
    border: 1px solid var(--light);
    text-decoration: none;
    &:hover {
      background: var(--orange);
      color: var(--light);
    }
  }
`;
