import React from 'react';
import {Container, Grid} from '@mui/material';
import { CustomTitle, CustomContainer,CustomText,CustomTextServices,CustomGridItem} from './style';
import ContainerLine from "../../../assets/images/container-line.svg";
import AspectRatio from "../../../assets/images/aspect-ratio.svg";
import BalanceScale from "../../../assets/images/balance-scale.svg";
import ButtonUnderline from '../../ui/buttons/buttonUnderline';

export default function Section6() {

  return (
    <CustomContainer maxWidth={false} disableGutters>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={6.5}>
            <CustomTitle>Mercadoria</CustomTitle>
            <CustomText>Tenha controlo sobre tudo relacionado com as movimentações da mercadoria (carga/descarga). Registros, considerar a movimentação em parque, pesagem da mercadoria, fiscalização.</CustomText>
            <ButtonUnderline>Saber mais</ButtonUnderline>
          </Grid>
        </Grid>
        <Grid container display={'flex'} gap={'33px'} marginTop={'88px'} >
          <CustomGridItem item xs={12} md={3}>
            <img src={ContainerLine} alt="" />
            <CustomTextServices>GESTÃO DE PÁTIO</CustomTextServices>
          <CustomText>Controlo e localização gráfica de mercadoria (contentorizada, granel e carga geral) em Pátio</CustomText>
          </CustomGridItem>
          <CustomGridItem item xs={12} md={3}>
            <img src={AspectRatio} alt="" />
            <CustomTextServices>INSPETORIA</CustomTextServices>
          <CustomText>Registo de boletins (automáticos ou upload pelo operador portuário)</CustomText>
          </CustomGridItem>
          <CustomGridItem item xs={12} md={3}>
            <img src={BalanceScale} alt="" />
            <CustomTextServices>BALANÇA</CustomTextServices>
          <CustomText>Pesagem de mercadoria entrada e saída de pátio</CustomText>
          </CustomGridItem>
        </Grid>
      </Container>
   </CustomContainer>
  )
}
