import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const underlineAnimation = keyframes`
    from{
        width:0px;
    }to {
        width:100%;
    }
`;

export const CustomLink = styled(Link)`
    &&{
        font-family:Poppins Semibold;
        font-size: 20px;
        color: var(--orange);   
        cursor:pointer;
        position:relative;
        width: max-content;
        display:flex;
        align-items:center;
        gap:16px;
        text-decoration:none;
        &:before{
            content:'';
            position:absolute;
            bottom:-2px;
            left:0;
            width:2%;
            height:2px;
            background-color:var(--orange);
            transition:width 0.3s ease-in-out;
        }
        &:hover{
            &:before{
                animation:${underlineAnimation} 0.3s ease-in-out forwards;
            }
        }
    }
`;