import {
  Container
} from "@mui/material";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const CustomContainer = styled(Container)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--light);
  height: 80px;
`;

export const Customimg = styled.img`
  width: 237px;
  height: 80px;
  @media (max-width: 600px) {
    width: 150px;
  }
`;

export const CustomLink = styled(Link)`
&&{
  text-decoration: none;
  color: var(--primary);
  font-size: 24px;
  font-family: Poppins Medium;
}
`;