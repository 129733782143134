import React from "react";
import { CustomTypography, CustomContainer } from "./style";
import ButtonOrange from "../../ui/buttons/buttonOrange";
import { Container } from "@mui/material";

export default function Section10() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="xl">
        <CustomTypography>
          Se está interessado não hesite em contactar ou deixar os seus dados
          para que o contactemos. Peça mais informações ou uma demonstração sem
          qualquer compromisso.
        </CustomTypography>
      </Container>
      <ButtonOrange>Contactar</ButtonOrange>
    </CustomContainer>
  );
}
