import React from "react";
import {
  CustomContainer,
  CustomText,
  CustomTitle,
  CustomImg,
  CustomImg2,
} from "./style";
import { Container, Grid } from "@mui/material";
import ButtonUnderline from "../../ui/buttons/buttonUnderline";
import LeftImg from "../../../assets/images/img_traf.png";
import RightImg from "../../../assets/images/img_merc.png";
export default function Section5() {
  return (
    <CustomContainer maxWidth={false} disableGutters>
      <CustomImg src={LeftImg} alt="" />
      <CustomImg2 src={RightImg} alt="" />
      <Container maxWidth="lg">
        <Grid
          container
          sx={{
            justifyContent: "end",
            "@media (max-width: 1500px)": {
              justifyContent: "start",
            },
          }}
        >
          <Grid item xs={12} md={6.5} lg={6.5}>
            <CustomTitle>Trâfego Aquaviário</CustomTitle>
            <CustomText>
              Planejamento e coordenação portuária. Planejamento de Manobras,
              como solicitação de manobra e confirmação pelo porto e entidades
              envolvidas, pauta e publicação, transferência de cais, atribuição
              automática (VTS e/ou AIS) e/ou manual de pontos de controle.
            </CustomText>
            <CustomText>
              Informe, registre e planeie a atividade de manobras das
              embarcações pelos práticos, empresas de estiva e empresas de
              prestação de serviços responsáveis.
            </CustomText>
            <ButtonUnderline>Saber mais</ButtonUnderline>
          </Grid>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
