import React, {useState} from 'react'
import { CustomLink } from './style.js';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import Modalcontact from '../../../forms/modalcontact';

export default function ButtonUnderline({children}) {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
       <CustomLink onClick={handleOpen}>{children}<EastRoundedIcon color='var(--orange)' /></CustomLink>
    {openModal && (
        <Modalcontact openModal={openModal} handleClose={handleClose} />
      )}
    </>
  )
}
