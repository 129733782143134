import { Container, Grid } from "@mui/material";
import React from "react";
import {
  CustomTitle,
  CustomBox,
  CustomSubtitle,
  CustomText,
  BoxButton,
  CustomContainer,
  BoxContact,
  BoxContactText,
  CustomImg,
} from "./style";
import ButtonOrange from "../../ui/buttons/buttonOrange";
import Circle from "../../../assets/images/circle.png";

export default function Section4() {
  return (
    <CustomContainer maxWidth="false" disableGutters>
      <Container maxWidth="lg">
        <CustomTitle>Os nossos serviços</CustomTitle>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomBox>
              <CustomSubtitle>Transformação Digital</CustomSubtitle>
              <CustomText>
                Pretendemos acabar com o papel através de melhorias de workflows
                que assim o permitam, aumentando ainda a eficiência da equipa.
              </CustomText>
              <BoxButton>
                <ButtonOrange>Saber mais</ButtonOrange>
              </BoxButton>
            </CustomBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomBox>
              <CustomSubtitle>Indústria 4.0</CustomSubtitle>
              <CustomText>
                Analisámos e apoiamos na transição estratégica para a indústria
                4.0. Em casos de necessidade, temos uma equipa de
                desenvolvimento especializada em criar soluções específicas do
                negócio.
              </CustomText>
              <BoxButton>
                <ButtonOrange>Saber mais</ButtonOrange>
              </BoxButton>
            </CustomBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomBox>
              <CustomSubtitle>Consultoria Empresarial</CustomSubtitle>
              <CustomText>
                Aprenda a construir o seu modelo de negócio. Realizar um
                planeamento estratégico e compreender que metodologias a sua
                equipa pode/deve adotar e como as implementar. Garanta a criação
                de valor continuo.
              </CustomText>
              <BoxButton>
                <ButtonOrange>Saber mais</ButtonOrange>
              </BoxButton>
            </CustomBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomBox>
              <CustomSubtitle>Portugal 2030</CustomSubtitle>
              <CustomText>
                Apoiamos na criação ou acompanhamento de projetos no âmbito do
                Sistema de Incentivos à Inovação Produtiva(PME's) e Investigação
                & Desenvolvimento Tecnológico.
              </CustomText>
              <BoxButton>
                <ButtonOrange>Saber mais</ButtonOrange>
              </BoxButton>
            </CustomBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomBox>
              <CustomSubtitle>Planos PRR</CustomSubtitle>
              <CustomText>
                Apoiamos na criação ou acompanhamento de projetos no âmbito do
                Plano de Recuperação e Resiliência português. Garanta que
                realmente obtém o que pretende com o máximo de apoio.
              </CustomText>
              <BoxButton>
                <ButtonOrange>Saber mais</ButtonOrange>
              </BoxButton>
            </CustomBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomBox>
              <CustomSubtitle>Scrum Agile</CustomSubtitle>
              <CustomText>
                Uma metodologia com sucesso comprovado mas que muitas vezes é
                aplicada ou implementada de forma errada, não conseguindo
                retirar o valor que a mesma oferece. Nós ajudamos nessa
                transição.
              </CustomText>
              <BoxButton>
                <ButtonOrange>Saber mais</ButtonOrange>
              </BoxButton>
            </CustomBox>
          </Grid>
        </Grid>
        <BoxContact>
          <BoxContactText>
            Contacte-nos e descubra o que podemos fazer para melhorar o seu
            negócio
          </BoxContactText>
          <CustomImg src={Circle} />
        </BoxContact>
      </Container>
    </CustomContainer>
  );
}
