import "./assets/css/App.css";
import Routes from "./routes/routes.js";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

window.addEventListener("scroll", function () {
  const body = document.querySelector("body");
  const windowHeight = window.innerHeight;
  const scrollValue = window.scrollY;
  const removeBG = 6000;

  if (
    scrollValue > removeBG ||
    scrollValue < 1000 ||
    window.location.pathname === "/privacy-policy" ||
    window.location.pathname === "/cookies-policy" ||
    window.location.pathname === "/terms-conditions" ||
    window.location.pathname === "/consulting"
  ) {
    body.classList.add("body-scroll");
  } else {
    body.classList.remove("body-scroll");
  }
});

function App() {
  return (
    <>
      <Header />
      <Routes />
      <Footer />
    </>
  );
}

export default App;
