import React, { useState } from "react";
import { CustomContainer, Customimg, CustomLink } from "./styles";
import { Grid, Container } from "@mui/material";
import Logo from "../../assets/images/canow.svg";
import { useLocation } from "react-router-dom";

export default function Header() {
  const [currentRoute, setCurrentRoute] = useState("/");
  const location = useLocation();

  return (
    <CustomContainer maxWidth={false}>
      <Container
        maxWidth="lg"
        disableGutters
        sx={{
          height: "100%",
        }}
      >
        <Grid
          container
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Customimg src={Logo} alt="" />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CustomLink to={location.pathname === "/" ? "/consulting" : "/"}>
              {location.pathname === "/" ? "Consultoria" : "Portos Brasil"}
            </CustomLink>
          </Grid>
        </Grid>
      </Container>
    </CustomContainer>
  );
}
