import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import Bubble from "../../../assets/images/Bubble.png";
import wave from "../../../assets/images/wave.svg";

export const CustomTitle = styled(Typography)`
  && {
    font-size: 36px;
    font-family: Poppins Medium;
    color: var(--primary);
    text-align: left;
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
`;
export const CustomText = styled(Typography)`
  && {
    font-size: 20px;
    font-family: Poppins Regular;
    color: var(--dark);
    text-align: left;
  }
`;

export const CustomBox = styled(Box)`
  && {
    border-radius: 10px;
    background: var(--light);
    padding: 32px;
    box-sizing: border-box;
    box-shadow: 0px 1px 6px #00f6ff5c;
    height: 487px;
    max-height: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 768px) {
      padding: 16px;
    }
    @media (max-width: 1200px) {
      height: max-content;
    }
    @media (min-width: 900px and max-width: 1200px) {
      min-height: content;
    }
  }
`;

export const CustomContainer = styled(Container)`
  && {
    background-image: url(${Bubble});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 208px;
    padding-bottom: 493px;
    box-sizing: border-box;
    position: relative;
    z-index: 0 !important;
    @media (max-width: 768px) {
      padding-top: 128px;
      padding-bottom: 300px;
    }
  }
`;

export const CustomTitle2 = styled(Typography)`
  && {
    font-size: 36px;
    font-family: Poppins Regular;
    color: var(--light);
    text-align: center;
    max-width: 1036px;
    margin-bottom: 32px;
    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
`;

export const CustomContainer2 = styled(Container)`
  && {
    background-image: url(${wave});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: transparent;
    box-sizing: border-box;
    width: 100%;
    height: 1019px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -320px;
    z-index: 1 !important;
    @media (max-width: 768px) {
      margin-top: -400px;
    }
  }
`;
