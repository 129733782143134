import { Container } from "@mui/material";
import React from "react";
import CanowSmartport from "../../../assets/images/canow_brasil.png";
import { CustomImg } from "./style";
import "./style.css";

export default function Section1() {
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{ maxHeight: "755px", position: "relative" }}
    >
      <CustomImg src={CanowSmartport} alt="" />
      <section id="section10" class="demo">
        <a href="/#port">
          <span></span>
        </a>
      </section>
    </Container>
  );
}
