import React from 'react'
import {CustomContainer,CustomBox, CustomTypography,CustomText, CustomTitle} from './style';
import { Container, Grid } from '@mui/material';

export default function Section9() {
  return (
    <CustomContainer maxWidth={false} disableGutters>
        <Container maxWidth="lg">
            <Grid container>
                <Grid item xs={12} display={'flex'} justifyContent={'center'} marginBottom={'64px'}>
                    <CustomTitle>Integrações</CustomTitle>
                </Grid>
            </Grid>
            <Grid container display={'flex'} gap={'32px'} justifyContent={'center'}>
                <Grid item >
                    <CustomBox>
                    <CustomTypography>Governo Federal</CustomTypography>
                    <CustomText>SYSCOMEX</CustomText>
                    <CustomText>Porto sem Papel</CustomText>
                    </CustomBox>
                </Grid>
                <Grid item>
                    <CustomBox>
                    <CustomTypography>B2A</CustomTypography>
                    <CustomText>Sistema de Autoridades Internacionais</CustomText>
                    </CustomBox>
                </Grid>
                <Grid item>
                    <CustomBox>
                    <CustomTypography>Sistemas no hinterland</CustomTypography>
                    <CustomText>Stackholders</CustomText>
                    </CustomBox>
                </Grid>
            </Grid>
            <Grid container display={'flex'} gap={'32px'} marginTop={'32px'} justifyContent={'center'}>
                <Grid item>
                    <CustomBox>
                    <CustomTypography>Sistemas no foreland</CustomTypography>
                    <CustomText>Outros Port Community System</CustomText>
                    </CustomBox>
                </Grid>
                <Grid item>
                    <CustomBox>
                    <CustomTypography>Outros ecosistemas</CustomTypography>
                    <CustomText>Agregadores de Serviços</CustomText>
                    </CustomBox>
                </Grid>
            </Grid>
        </Container>
    </CustomContainer>
  )
}
