import React from "react";
import { Container, Grid } from "@mui/material";
import {
  CustomTitle,
  CustomTitle2,
  CustomText,
  CustomBox,
  CustomContainer,
  CustomContainer2,
} from "./style.js";
import ButtonUnderline from "../../ui/buttons/buttonUnderline";
import ButtonWhite from "../../ui/buttons/buttonWhite";

export default function Section2() {
  return (
    <Container
      maxWidth="false"
      disableGutters
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomContainer maxWidth="false" disableGutters>
        <Container maxWidth="lg">
          <Grid
            container
            display={"flex"}
            gap={"32px"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={12} md={8} lg>
              <CustomBox>
                <CustomTitle>Quem somos</CustomTitle>
                <CustomText>
                  Somos conhecidos no mercado pelo desenvolvimento da{" "}
                  <span style={{ color: "var(--primary)", fontWeight: "bold" }}>
                    JUP
                  </span>
                  , no entanto, esta é apenas uma pequena parte da consultoria e
                  desenvolvimento que realizamos. Trabalhamos com agentes de
                  navegação, transitários, parques de contentores, logística,
                  financeira e outros casos mais específicos.
                </CustomText>
                <ButtonUnderline>Saber mais</ButtonUnderline>
              </CustomBox>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg>
              <CustomBox>
                <CustomTitle>Como trabalhamos</CustomTitle>
                <CustomText>
                  Mobilizamos equipas ou Indíviduos para atender aos requisitos
                  específicos do projeto. A nossa experiência permite-nos uma
                  fácil compreensão do negócio de forma a garantir uma entrega
                  de valor.
                </CustomText>
                <CustomText>
                  Trabalhamos com uma metodologia de{" "}
                  <span style={{ color: "var(--primary)", fontWeight: "bold" }}>
                    Agile Scrum
                  </span>{" "}
                  que nos permite desenvolver valor em pequenos espaços de tempo
                  e contabilizar todo o desenvolvimento de forma precisa, tal
                  como reagir a problemas e à necessidade de mudança de forma
                  rápida e flexível.
                </CustomText>
              </CustomBox>
            </Grid>
          </Grid>
        </Container>
      </CustomContainer>
      <CustomContainer2 maxWidth="false" disableGutters>
        <Container maxWidth="lg" align="center">
          <CustomTitle2>
            Providenciamos ajuda na transformação digital, transição para um
            Green Port / Smart Port.
          </CustomTitle2>
          <ButtonWhite>Entrar em contacto</ButtonWhite>
        </Container>
      </CustomContainer2>
    </Container>
  );
}
