import React from "react";
import { Container, Grid } from "@mui/material";
import {
  CustomTitle,
  CustomSubTitle,
  CustomText,
  CustomBox,
  CustomContainer,
  CustomBox2,
} from "./style.js";
import WhiteButton from "../../ui/buttons/buttonWhite";

export default function Section1() {
  return (
    <>
      <CustomContainer maxWidth="false" disableGutters>
        <Container maxWidth="lg">
          <Grid container display={"flex"} alignItems={"center"}>
            <Grid item xs={12} md={6} lg={4}>
              <CustomTitle>Portos Consultoria & Soluções</CustomTitle>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={8}
              justifyContent={"end"}
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <CustomBox>
                <CustomSubTitle>
                  O desafio atual das redes portuárias e logísticas
                </CustomSubTitle>
                <CustomText>
                  O forte crescimento destes setores leva a novas necessidades
                  de adaptação. Mais do que nunca é necessário criar sistemas
                  que suportem a ligação destes setores para que os mesmo
                  continuem a crescer. Todos os Portos que não se tornem em
                  SmartPorts serão lentamente deixados para trás.
                </CustomText>
                <CustomText>
                  <b>
                    Nós temos um objetivo, criar soluções para todos os
                    desafios. Encaramos desafios como oportunidades.
                  </b>
                </CustomText>
                <CustomBox2 />
              </CustomBox>
            </Grid>
            <Grid item xs={12} md={4}></Grid>
            <Grid
              item
              xs={12}
              md={8}
              sx={{ paddingLeft: { xs: "0", md: "52px" } }}
              marginTop={"32px"}
            >
              <WhiteButton>Contactar</WhiteButton>
            </Grid>
          </Grid>
        </Container>
      </CustomContainer>
    </>
  );
}
