import styled from "styled-components";
import { Box, Container, Typography } from "@mui/material";

export const CustomContainer = styled(Container)`
  && {
    padding-bottom: 277px;
    background: var(--light);
    @media (max-width: 768px) {
      padding-bottom: 128px;
    }
  }
`;

export const CustomBox = styled(Box)`
  && {
    border-radius: 10px;
    background: var(--primary);
    width: 346px;
    box-shadow: 0px 3px 3px var(--gray-3);
    padding: 32px;
    box-sizing: border-box;
    min-height: 185px;
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 56px;
    color: var(--primary);
    @media (max-width: 768px) {
      font-size: 48px;
    }
  }
`;

export const CustomTypography = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 23px;
    color: var(--light);
    margin-bottom: 16px;
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--light);
  }
`;
