import React from 'react'
import {CustomContainer, CustomText, CustomTitle,CustomGrid,CustomTypography} from './style';
import { Container, Grid } from '@mui/material';
import Cloud from "../../../assets/images/cloud.svg";
import Pcgear from "../../../assets/images/pcgear.svg";
import Pc from "../../../assets/images/pc.svg";

export default function Section8() {
  return (
    <CustomContainer maxWidth={false} disableGutters>
        <Container maxWidth="xl">
            <Grid container>
                <Grid item xs={12} display={'flex'} justifyContent={'center'} marginBottom={'64px'}>
                    <CustomTypography>Benefícios</CustomTypography>
                </Grid>
            </Grid>
            <Grid container display={'flex'} alignItems={'center'} gap={'34px'} justifyContent={'center'}>
                <CustomGrid item xs={12} md>
                    <img src={Cloud} alt="" />
                    <CustomTitle>Simplifique o acesso a dados</CustomTitle>
                    <CustomText>Todos os seus dados num só local, acessíveis a partir da nuvem em qualquer lugar, a qualquer hora</CustomText>
                </CustomGrid>
                <CustomGrid item xs={12} md >
                    <img src={Pcgear} alt="" />
                    <CustomTitle>Analise dados em tempo real</CustomTitle>
                    <CustomText>Algoritmos de inteligência artificial em constante aprendizagem de forma a melhorar a sua eficiência e sustentabilidade</CustomText>
                </CustomGrid>
                <CustomGrid item xs={12} md>
                    <img src={Pc} alt="" />
                    <CustomTitle>Visualize indicadores chave</CustomTitle>
                    <CustomText>Tenha acesso a várias métricas e previsões que facilitam a tomada de decisão com base nos dados do negócio</CustomText>
                </CustomGrid>
            </Grid>
        </Container>
    </CustomContainer>
  )
}
