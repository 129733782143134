import React from "react";
import { Box, Container, Grid } from "@mui/material";
import SmartPorts from "../../../assets/images/smartports.svg";
import { CustomTitle, CustomText, CustomTextOrange } from "./style.js";

export default function Section11() {
  return (
    <Container
      maxWidth={false}
      disableGutters
      paddingTop={"50px"}
      sx={{ background: "var(--light)" }}
    >
      <img
        src={SmartPorts}
        style={{ maxWidth: "100%", marginTop: "128px" }}
        alt=""
      />
      <Grid container>
        <Grid item xs={12} md={6} lg={6} sx={{ paddingTop: "86px" }}>
          <Grid container>
            <Grid item md={1.0} lg={1.0} xl={4.0}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={9}
              xl={6.5}
              sx={{
                padding: { xs: "16px", lg: "0px" },
                marginBottom: { xs: "64px", lg: "0" },
              }}
            >
              <CustomTitle>O que são Port Community Systems?</CustomTitle>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                marginBottom={"32px"}
              >
                <CustomText>
                  Uma plataforma eletrônica neutra e aberta que permite a troca
                  de informações inteligente e segura entre stakeholders
                  públicos e privados a fim de melhorar a posição competitiva
                  das empresas de portos marítimos e aéreos.
                </CustomText>
                <CustomText>
                  Um sistema que otimiza, gerência e automatiza processos
                  portuários e logísticos através de um único envio de dados,
                  conetando as cadeias de transporte e logística.
                </CustomText>
                <CustomText>
                  Estes sistemas irão desempenhar um papel importante à medida
                  que os países avançam para o ambiente de Janela Única.
                </CustomText>
              </Box>
              <CustomTextOrange>
                “O processo portuário é afetado pela baixa qualidade de
                integração dos sistemas e falta de coordenação entre os
                stakeholders e autoridades envolvidas”
              </CustomTextOrange>
            </Grid>
            <Grid item md={1.0} lg={1.5} xl={1.5}></Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            background: "var(--light-2)",
            paddingTop: { xs: "64px", lg: "86px" },
            paddingBottom: { xs: "64px", lg: "0" },
          }}
        >
          <Grid container>
            <Grid item md={1.0} lg={1.5} xl={1.5}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={10}
              lg={9}
              xl={6.5}
              sx={{ padding: { xs: "16px", lg: "0px" } }}
            >
              <CustomTitle>
                Por que a digitalização é crucial para os portos?
              </CustomTitle>
              <CustomTextOrange>
                Todos os Portos que não se tornem em SmartPorts serão lentamente
                deixados para trás.
              </CustomTextOrange>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                marginTop={"32px"}
              >
                <CustomText>
                  A indústria marítima e de transporte marítimo está atrasada em
                  termos de digitalização, a sua adaptação terá impactos enormes
                  para empresas de logística e portos.
                </CustomText>
                <CustomText>
                  Os portos são um ponto da cadeia de suprimentos e se querem
                  autoridade deverão ter pontos nas cadeias físicas e digitais.
                  Ao lado de qualquer porto deverá estar um porto digital, para
                  permitir uma manutenção preditiva e atracações que são
                  fisicamente inacessíveis.
                </CustomText>
              </Box>
            </Grid>
            <Grid item md={1.0} lg={1.0} xl={4.0}></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
