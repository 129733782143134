import styled from "styled-components";
import { Container, Grid, Typography} from "@mui/material";

export const CustomContainer = styled(Container)`
    &&{
        padding-top:313px;
        padding-bottom: 250px;
        background:var(--light);
        @media (max-width: 768px) {
            padding-top:128px;
            padding-bottom: 128px;
        }
    }
`;

export const CustomTypography = styled(Typography)`
&&{
    font-family:Poppins Semibold;
    font-size: 56px;
    color: var(--orange);
}
`;

export const CustomTitle = styled(Typography)`
&&{
    font-family:Poppins Medium;
    font-size: 24px;
    color: var(--primary);
    width: 100%;
}
`;

export const CustomText = styled(Typography)`
&&{
    font-family:Poppins Regular;
    font-size: 20px;
    color: var(--dark);
}
`;

export const CustomGrid = styled(Grid)`
    &&{
        display:flex;
        flex-direction:column;
        align-items:center;
        max-width: 394px;
        gap: 16px;
    }
`;