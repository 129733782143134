import styled from "styled-components";
import BG from "../../../assets/images/Sec7bg.svg";
import { Container, Grid, Typography } from "@mui/material";

export const CustomContainer = styled(Container)`
  && {
    background-image: url(${BG});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    padding-top: 217px;
    padding-bottom: 500px;
    background-position: center;
    @media (max-width: 768px) {
      padding-top: 64px;
      padding-bottom: 64px;
      // linear gradient #183DF9 100% to #00F6FF 26%
      background: linear-gradient(180deg, #183df9 50%, #9ceeff 100%);
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 56px;
    color: var(--light);
    margin-bottom: 32px;
    @media (max-width: 768px) {
      font-size: 48px;
    }
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--light);
    margin-bottom: 32px;
  }
`;

export const CustomText2 = styled(Typography)`
  && {
    font-family: Poppins Light;
    font-size: 12px;
    color: var(--light);
  }
`;

export const CustomGrid = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: end;
    @media (max-width: 900px) {
      align-items: start;
    }
    @media (min-width: 901px) {
      align-items: end;
    }
  }
`;
