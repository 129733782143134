import { Grid, List } from "@mui/material";
import React from "react";
import {
  CustomTypography,
  CustomContainer,
  CustomContainerChild,
  CustomBox,
  CustomTypography2,
  CustomListItem,
  CustomImg,
} from "./style";
import PortSystem from "../../../assets/images/portsystem.svg";

export default function Section2() {
  return (
    <CustomContainer id="port" maxWidth={false} disableGutters>
      <CustomContainerChild maxWidth="xl">
        <CustomTypography>
          Intelligent Port Community System and Harbour Logistics Operations
        </CustomTypography>
        <Grid container justifyContent={"center"} marginTop={"174px"}>
          <Grid item xs={12} md={5}>
            <CustomImg src={PortSystem} alt="" />
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            display={"flex"}
            alignItems={"center"}
            sx={{
              justifyContent: { xs: "center", md: "end" },
              marginTop: { xs: "64px", lg: "0px" },
            }}
          >
            <CustomBox>
              <CustomTypography2>Soluções Integradas</CustomTypography2>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 3,
                  "& .MuiListItem-root": {
                    display: "list-item",
                    pl: 0,
                    pb: 1,
                    pt: 0,
                  },
                }}
              >
                <CustomListItem>Administração Portuária</CustomListItem>
                <CustomListItem>Despachantes Oficiais</CustomListItem>
                <CustomListItem>Concessionários</CustomListItem>
                <CustomListItem>Empresas de Estiva</CustomListItem>
                <CustomListItem>
                  Empresas Prestadoras de Serviços a navios
                </CustomListItem>
                <CustomListItem>Arrendatários</CustomListItem>
                <CustomListItem>Praticagem</CustomListItem>
                <CustomListItem>Empresas de Rebocadores</CustomListItem>
                <CustomListItem>Receita Federal</CustomListItem>
                <CustomListItem>ANTAQ</CustomListItem>
                <CustomListItem>Exército</CustomListItem>
                <CustomListItem>IBAMA</CustomListItem>
                <CustomListItem>Ministério da Agricultura</CustomListItem>
                <CustomListItem>Capitania / DHN - Marinha</CustomListItem>
                <CustomListItem>
                  Receita Estadual (Secretaria da Fazenda)
                </CustomListItem>
              </List>
            </CustomBox>
          </Grid>
        </Grid>
      </CustomContainerChild>
    </CustomContainer>
  );
}
