import { Box, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomCard = styled(Box)`
&&{
    background: var(--light);
    border-radius: 5px;
    box-shadow: 0px 3px 6px var(--gray-3);
    padding:16px;
    box-sizing: border-box;
    max-width: 360px;
    display: flex;
    gap:16px;
    @media (max-width:768px) {
        max-width: 360px;
    }
    @media (max-width: 596px){
        max-width: 100%;
    }
}
`;

export const CustomText = styled(Typography)`
&&{
    font-family: Poppins Medium;
    font-size: 16px;
    color: var(--primary);
}
`;