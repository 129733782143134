import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Privacy from "../pages/Privacy";
import Cookies from "../pages/Cookies";
import Terms from "../pages/Terms&Conditions";
import Consultoria from "../pages/Consultoria/Consultoria";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<Privacy />} />
      <Route path="/cookies-policy" element={<Cookies />} />
      <Route path="/terms-conditions" element={<Terms />} />
      <Route path="/consulting" element={<Consultoria />} />
    </Routes>
  );
}

export default AppRoutes;
