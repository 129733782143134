import styled from "styled-components";
import BG from "../../assets/images/Footer.png";
import { Button, Container, Typography } from "@mui/material";

export const CustomContainer = styled(Container)`
  && {
    background-image: url(${BG});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    min-height: 100%;
    padding-top: 296px;
    margin-top: -130px;
    max-width: 100%;
    height: auto;
    @media (max-width: 768px) {
      margin-top: 0px;
    }
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--light);
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 20px;
    color: var(--orange);
    margin-bottom: 32px;
  }
`;

export const CustomSubTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 12px;
    color: var(--orange);
  }
`;

export const CustomButton = styled(Button)`
  && {
    padding: 0;
    min-width: max-content;
    min-height: max-content;
    cursor: pointer;
  }
`;
