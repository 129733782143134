import { Button } from "@mui/material";
import styled from "styled-components";

export const CustomButton = styled(Button)`
    &&{
        background: var(--orange);
        border-radius: 10px;
        box-shadow: 0px 1px 3px var(--gray-3);
        box-sizing: border-box;
        padding: 16px 24px;
        width: max-content;
        height: 56px;
        font-family: Poppins Semibold;
        font-size: 20px;
        color: var(--light);
        text-transform: none;
        &:hover{
            background: var(--light);
            color: var(--orange);
        }
    }
`;