import React from "react";
import {
  CustomContainer,
  CustomText,
  CustomTitle,
  CustomSubTitle,
  CustomButton,
} from "./styles";
import { Box, Container, Grid } from "@mui/material";
import Jcanao from "../../assets/images/canowFooter.svg";
import Linkedin from "../../assets/icons/linkedin.svg";
import Facebook from "../../assets/icons/facebook.svg";
import Certificacoes from "../../assets/logo/certifs.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();

  const isConsultingRoute = location.pathname === "/consulting";

  if (isConsultingRoute) {
    return null;
  }

  return (
    <>
      <CustomContainer maxWidth="false" disableGutters>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item sx={12} md={12} marginBottom={"32px"}>
              <img
                src={Jcanao}
                style={{ maxWidth: "100%", height: "auto" }}
                width={288}
                alt=""
              />
            </Grid>
            <Grid container display={"flex"} justifyContent={"space-between"}>
              <Grid
                item
                xs={12}
                md={6}
                lg={2.5}
                sx={{ marginBottom: { xs: "64px", lg: "0px" } }}
              >
                <CustomTitle>Contatos</CustomTitle>
                <Box display={"flex"} gap={"12px"} flexDirection={"column"}>
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:comercial@jcanao.pt"
                  >
                    <CustomText>comercial@canow.com.br</CustomText>
                  </a>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={2.5}
                sx={{ marginBottom: { xs: "64px", lg: "0px" } }}
              >
                <CustomTitle>Rede Sociais</CustomTitle>
                <Box display={"flex"} gap={"8px"}>
                  <Link
                    to="https://pt.linkedin.com/company/jcanao"
                    target="_blank"
                  >
                    <CustomButton>
                      <img src={Linkedin} alt="" />
                    </CustomButton>
                  </Link>
                  <Link
                    to="https://www.facebook.com/jcanao.pt/?locale=pt_PT"
                    target="_blank"
                  >
                    <CustomButton>
                      <img src={Facebook} alt="" />
                    </CustomButton>
                  </Link>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={2.5}
                sx={{ marginBottom: { xs: "64px", lg: "0px" } }}
              >
                <CustomTitle>Legal</CustomTitle>
                <Box display={"flex"} gap={"12px"} flexDirection={"column"}>
                  <a href="/cookies-policy" style={{ textDecoration: "none" }}>
                    <CustomText>Política de Cookies</CustomText>
                  </a>
                  <a href="/privacy-policy" style={{ textDecoration: "none" }}>
                    <CustomText>Política de Privacidade</CustomText>
                  </a>
                  <a
                    href="/terms-conditions"
                    style={{ textDecoration: "none" }}
                  >
                    <CustomText>Termos e Condições</CustomText>
                  </a>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={3}
                sx={{ marginBottom: { xs: "64px", lg: "0px" } }}
              >
                <CustomTitle>Certificações</CustomTitle>
                <Box display={"flex"} gap={"12px"} flexDirection={"column"}>
                  <img
                    src={Certificacoes}
                    alt=""
                    style={{ maxWidth: "280px" }}
                  />
                  <span
                    style={{
                      fontFamily: "Poppins Regular",
                      fontSize: "9px",
                      color: "var(--light)",
                    }}
                  >
                    Âmbito SGQ: Conceção, Design, Desenvolvimento e Consultoria
                    para Projetos de Software e Comercialização e Suporte de
                    Hardware.
                  </span>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "auto",
              marginTop: "64px",
              paddingBottom: "8px",
            }}
          >
            <CustomText>
              © 2023 J. Canão - IT solutions & consulting. All rights reserved.
            </CustomText>
          </Box>
        </Container>
      </CustomContainer>
    </>
  );
}
