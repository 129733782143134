import { Container, Grid } from '@mui/material';
import {CustomContainer, CustomTitle,CustomText,CustomTitle2} from './style.js';
import React from 'react';
import Button from "../../ui/buttons/buttonOrange";

export default function Section12() {
  return (
    <Container maxWidth={false} disableGutters sx={{background:'var(--light)', marginTop:{xs:'128px', lg:'0px'}}}>
    <CustomContainer maxWidth={false} disableGutters>
        <Container maxWidth="lg">
            <Grid container display={'flex'} justifyContent={'center'}>
                <Grid item xs={12} md={12} >
                    <CustomTitle>Tecnologias de Smart Ports</CustomTitle>
                </Grid>
                <Grid container display={'flex'} sx={{gap:{xs:'64px', lg:'33px'}}} marginBottom={'64px'}>
                <Grid item xs={12} md >
                    <CustomTitle2>Big Data</CustomTitle2>
                    <CustomText>Big Data contribui para a tecnologia preditiva e sistemas de tempo estimado de chegada (ETA) que mudarão o setor de transporte marítimo. Entrada de dados precisas levam os portos ao pico de eficiência, desempenho e crescimento.</CustomText>
                </Grid>
                <Grid item xs={12} md >
                    <CustomTitle2>Inteligência Artificial (AI)</CustomTitle2>
                    <CustomText>Melhora as operações do negócio e a sua eficiência. Facilita na tomada de decisões devido ao seu factor preditivo de operações portuárias, criando um ambiente seguro, com menores errors e acidentes.</CustomText>
                </Grid>
                <Grid item xs={12} md >
                    <CustomTitle2>Internet of Things (IoT)</CustomTitle2>
                    <CustomText>Permite interligar vários dispositivos diferentes, para obter como por exemplo a identificação e detalhe de cada navio e a sua carga.</CustomText>
                </Grid>
                </Grid>
                <Grid container display={'flex'} sx={{gap:{xs:'64px', lg:'33px'}}} marginBottom={'64px'}>
                    <Grid item xs={12} md>
                        <CustomTitle2>Blockchain</CustomTitle2>
                        <CustomText>Permite armazenar e ter acesso a dados  imutáveis em tempo real de forma a puder acompanhar cada evento que ocorra na cadeia de suprimentos global..</CustomText>
                    </Grid>
                    <Grid item xs={12} md>
                        <CustomTitle2>Rede 5G</CustomTitle2>
                        <CustomText>Permite uma comunicação como nunca antes,   especialmente em Portos, onde muitas vezes os sinais eram perdidos devido a existirem muitas superfícies metálicas. Com 5G isso já não é um problema.</CustomText>
                    </Grid>
                    <Grid item xs={12} md />
                </Grid>
                <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    <Button>Saber mais</Button>
                </Grid>
            </Grid>
        </Container>
    </CustomContainer>
    </Container>
  )
}
