import React from 'react'
import {CustomCard,CustomText} from './style';
import Gear from "../../assets/images/gears.svg";
import Database from "../../assets/images/database.svg";
import Processor from "../../assets/images/processor.svg";
import Lamp from "../../assets/images/ai-results-low.svg";
import Cloud from "../../assets/images/CloudIOT.svg";
import data from "../../data/estatistica.json";

export default function Card() {
  const getImage = (image) => {
    switch (image) {
      case "Gear":
        return Gear;
      case "Database":
        return Database;
      case "Processor":
        return Processor;
      case "Lamp":
        return Lamp;
      case "Cloud":
        return Cloud;
      default:
        return null;
    }
  }
  return (
   <>
   {data.map((item, index)=>(
    <CustomCard key={index}>
        <img src={getImage(item.image)} alt = "" />
       <CustomText>{item.text }</CustomText>
    </CustomCard>
     ))}
    </>
  )
}
