import React, {useState} from 'react'
import { CustomButton } from './style';
import Modalcontact from '../../../forms/modalcontact';

export default function ButtonOrange({children}) {
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <>
   <CustomButton onClick={handleOpen}>{children}</CustomButton>
   {openModal && (
        <Modalcontact openModal={openModal} handleClose={handleClose} />
      )}
   </>
  )
}
