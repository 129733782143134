import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import Servicos from "../../../assets/images/part3.png";

export const CustomContainer = styled(Container)`
  && {
    padding-top: 175px;
    padding-bottom: 450px;
    background-image: url(${Servicos});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: -2;
    @media (max-width: 768px) {
      padding-top: 0px;
      padding-bottom: 350px;
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-size: 76px;
    font-family: Poppins Semibold;
    color: var(--primary);
    text-align: left;
    margin-bottom: 80px;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
`;

export const CustomBox = styled(Box)`
  && {
    border-radius: 10px;
    background-color: var(--light);
    box-shadow: 0px 1px 3px #00f6ff5c;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 26px;
    height: 100%;
  }
`;

export const CustomSubtitle = styled(Typography)`
  && {
    font-size: 24px;
    font-family: Poppins Medium;
    color: var(--primary);
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-size: 16px;
    font-family: Poppins Regular;
    color: var(--dark);
  }
`;

export const BoxButton = styled(Box)`
  && {
    display: flex;
    margin-top: auto;
  }
`;

export const BoxContact = styled(Box)`
  && {
    border-radius: 10px;
    background-color: var(--orange);
    box-shadow: 0px 3px 6px #00000029;
    padding: 26px 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 137px;
    max-width: 500px;
    position: relative;
    &:hover {
      background-color: var(--primary);
    }
  }
`;

export const BoxContactText = styled(Typography)`
  && {
    font-size: 20px;
    font-family: Poppins Medium;
    color: var(--light);
    text-align: center;
  }
`;

export const CustomImg = styled.img`
  && {
    position: absolute;
    bottom: -700px;
    left: -700px;
    width: 1400px;
    z-index: -1;
    @media (max-width: 900px) {
      display: none;
    }
  }
`;
