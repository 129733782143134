import React from "react";
import Section1 from "../../components/sectionConsultoria/section1";
import Section2 from "../../components/sectionConsultoria/section2";
import Section4 from "../../components/sectionConsultoria/section4";
import Section5 from "../../components/sectionConsultoria/section5";
import Footer from "../../layouts/Footer2";

export default function Consultoria() {
  return (
    <>
      <Section1 />
      <Section2 />
      <Section4 />
      <Section5 />
      <Footer />
    </>
  );
}
