import { Container, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomContainer = styled(Container)`
  && {
    padding-top: 278px;
    padding-bottom: 256px;
    background: var(--light);
    position: relative;
    @media (max-width: 768px) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
    @media (max-width: 1500px) {
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 56px;
    color: var(--primary);
    margin-bottom: 32px;
    @media (max-width: 768px) {
      font-size: 48px;
    }
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--dark);
    margin-bottom: 32px;
  }
`;

export const CustomImg = styled.img`
  && {
    position: absolute;
    left: 0px;
    top: -100px;
    width: auto;
    height: auto;
    object-fit: contain;
    z-index: 1;
    @media (max-width: 1500px) {
      display: none;
    }
    @media (max-width: 1600px) {
      left: -200px;
    }
  }
`;

export const CustomImg2 = styled.img`
  && {
    position: absolute;
    right: 0%;
    top: 550px;
    width: auto;
    height: auto;
    object-fit: contain;
    z-index: 1;
    @media (max-width: 1500px) {
      display: none;
    }
    @media (max-width: 1600px) {
      right: -250px;
    }
  }
`;
