import styled from "styled-components";
import BG from "../../../assets/images/bgsec11.svg";
import { Container, Typography } from "@mui/material";

export const CustomContainer = styled(Container)`
  && {
    background-image: url(${BG});
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    min-height: 100%;
    width: 100%;
    padding-top: 690px;
    padding-bottom: 445px;
    @media (max-width: 768px) {
      padding-top: 128px;
      padding-bottom: 128px;
      background-size: cover;
      background: var(--primary);
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 56px;
    color: var(--orange);
    text-shadow: 0px 0px 6px var(--gray-3);
    text-align: center;
    margin-bottom: 64px;
    @media (max-width: 768px) {
      font-size: 48px;
    }
  }
`;
export const CustomTitle2 = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 24px;
    color: var(--light);
    margin-bottom: 16px;
  }
`;
export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    color: var(--light);
  }
`;
