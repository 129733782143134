import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomContainer = styled(Container)`
  && {
    background: transparent;
    padding-top: 335px;
    padding-bottom: 298px;
    @media (max-width: 768px) {
      padding-top: 128px;
      padding-bottom: 64px;
    }
  }
`;

export const CustomContainerChild = styled(Container)`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CustomBox = styled(Box)`
  && {
    padding: 64px;
    background: var(--light);
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px var(--gray-3);
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 823px;
    margin-bottom: 64px;
    @media (max-width: 768px) {
      padding: 32px;
    }
  }
`;

export const CustomTitle = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 26px;
    color: var(--primary);
  }
`;

export const CustomText = styled(Typography)`
  && {
    font-family: Poppins Regular;
    font-size: 20px;
    color: var(--dark);
  }
`;
