import { Button } from "@mui/material";
import styled from "styled-components";

export const CustomButton = styled(Button)`
  && {
    background: var(--light);
    border-radius: 10px;
    box-shadow: 0px 0px 6px #a5b9ba;
    box-sizing: border-box;
    padding: 12px 24px;
    width: max-content;
    height: 56px;
    font-family: Poppins Medium;
    font-size: 20px;
    color: var(--orange);
    text-transform: none;
    border: 1px solid var(--light);
    &:hover {
      background: var(--orange);
      color: var(--light);
    }
  }
`;
