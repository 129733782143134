import styled, { keyframes } from "styled-components";

const sdb10 = keyframes`
    0% {
        transform: translate(0,0);
        opacity: 0;
    }
    40%{
        opacity: 1;
    }
    80%{
        transform: translate(0,20px);
        opacity:0;
    }
    100% {
        opacity:0;
    }
`;

export const CustomImg = styled.img`
  && {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledLink = styled.a`
  position: relative;
  bottom: 0px;
  left: 50% span {
    position: absolute;
    top: 0;
    left: 50%;
    width: 30px;
    height: 50px;
    margin-left: -15px;
    border: 2px solid #000000;
    border-radius: 50px;
    box-sizing: border-box;
  }

  span::before {
    position: absolute;
    top: 10px;
    left: 50%;
    content: "";
    width: 6px;
    height: 6px;
    margin-left: -3px;
    background-color: #000000;
    border-radius: 100%;
    animation: ${sdb10} 2s infinite;
    box-sizing: border-box;
  }
`;
