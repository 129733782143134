import { Box, Container, ListItem, Typography } from "@mui/material";
import styled from "styled-components";

export const CustomContainer = styled(Container)`
  && {
    background: var(--light-2);
  }
`;

export const CustomContainerChild = styled(Container)`
  && {
    padding-top: 64px;
    padding-bottom: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const CustomTypography = styled(Typography)`
  && {
    font-family: Poppins Medium;
    font-size: 36px;
    text-align: center;
    color: var(--primary);
    max-width: 650px;
  }
`;

export const CustomTypography2 = styled(Typography)`
  && {
    font-family: Poppins Semibold;
    font-size: 24px;
    text-align: left;
    color: var(--primary);
  }
`;

export const CustomBox = styled(Box)`
  && {
    background: var(--light);
    border-radius: 8px;
    padding: 32px;
    box-sizing: border-box;
    max-width: 325px;
    @media (max-width: 900px) {
      max-width: 100%;
      padding: 16px;
    }
  }
`;

export const CustomListItem = styled(ListItem)`
  && {
    font-family: Poppins Regular;
    font-size: 16px;
    text-align: left;
    color: var(--orange-light);
  }
`;

export const CustomImg = styled.img`
  && {
    max-width: 100%;
    height: auto;
  }
`;
