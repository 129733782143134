import { Box, Container, Typography } from "@mui/material";
import styled from "styled-components";
import Entry from "../../../assets/images/entry.png";

export const CustomTitle = styled(Typography)`
  && {
    font-size: 66px;
    font-family: Poppins Medium;
    color: var(--light);
    text-align: left;
    text-shadow: 0px 0px 6px #00f6ff;
    @media (max-width: 768px) {
      font-size: 36px;
    }
  }
`;
export const CustomSubTitle = styled(Typography)`
  && {
    font-size: 26px;
    font-family: Poppins Medium;
    color: var(--light);
    text-align: left;
  }
`;
export const CustomText = styled(Typography)`
  && {
    font-size: 20px;
    font-family: Poppins Regular;
    color: var(--light);
    text-align: left;
  }
`;

export const CustomBox = styled(Box)`
  && {
    border-radius: 5px;
    background: transparent;
    padding: 32px;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px #00f6ff;
    backdrop-filter: blur(5px);
    max-width: 716px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
`;

export const CustomBox2 = styled(Box)`
  border-radius: 5px;
  background: linear-gradient(
    rgba(43, 141, 223, 0.56),
    rgba(49, 192, 246, 0.56)
  );
  padding: 32px;
  box-sizing: border-box;
  box-shadow: 0px 0px 3px #00f6ff;
  border: 1px solid var(--light);
  backdrop-filter: blur(30px);
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const CustomContainer = styled(Container)`
  && {
    background-image: url(${Entry});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 331px;
    padding-bottom: 323px;
    box-sizing: border-box;
    @media (max-width: 960px) {
      padding-top: 128px;
      padding-bottom: 128px;
    }
  }
`;
