import React from "react";
import {
  CustomContainer,
  CustomContainerChild,
  CustomText,
  CustomTitle,
  CustomBox,
} from "./style";
import ButtonOrange from "../../ui/buttons/buttonOrange";

export default function Section3() {
  return (
    <CustomContainer maxWidth={false} disableGutters>
      <CustomContainerChild maxWidth="lg">
        <CustomBox>
          <CustomTitle>
            Um sistema que integra respostas para todas as necessidades de
            Gestão das Operações Portuárias
          </CustomTitle>
          <CustomText>
            Reduz todos os custos operacionais e oferece um maior grau de
            confiança na toma de decisões com base em informações atualizadas e
            confiáveis. Tudo isto eleva a qualidade e eficiência de serviços,
            aumentando a competitividade.
          </CustomText>
        </CustomBox>
        <ButtonOrange>Deseja saber mais?</ButtonOrange>
      </CustomContainerChild>
    </CustomContainer>
  );
}
